import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header({toggleLanguage, translateIcon, isCookieFreePage}) {
    const [isNavOpen, setIsNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (isNavOpen) {
                setIsNavOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isNavOpen]);

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const closeNav = () => {
        setIsNavOpen(false);
    };

    return (
        <header className="header-container">
            <h1>SkanEat</h1>
            <div className={`hamburger ${isNavOpen ? 'active' : ''} ${isCookieFreePage ? 'hidden' : ''}`} onClick={toggleNav}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav className={`main-nav ${isNavOpen ? 'nav-open' : ''} ${isCookieFreePage ? 'hidden' : ''}`}>
                <Link to="/"  onClick={closeNav}>Accueil</Link>
                <Link to="/features"  onClick={closeNav}>Fonctionnalités</Link>
                <Link to="/pricing"  onClick={closeNav}>Tarifs</Link>
                <Link to="/faq"  onClick={closeNav}>FAQ</Link>
                <Link to="/contact"  onClick={closeNav}>Contact</Link>
            </nav>
            {/*
            <button onClick={toggleLanguage} className='langue' disabled={isCookieFreePage}>
                <img src={translateIcon} alt="Translate" style={{ width: '30px', height: '30px' }} />
            </button>
            */}
        </header>
    );
}

export default Header;
