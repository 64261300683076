import React from 'react';
import CookieConsent from "react-cookie-consent";

function CustomCookieConsent({ language, handleUserPreference, cookiesAccepted }) {
    return (
        <CookieConsent
            location="none"
            buttonText={language === "fr" ? "Accepter" : "Accept"}
            declineButtonText={language === "fr" ? "Refuser" : "Decline"}
            cookieName="skaneat_cookies"
            enableDeclineButton={true}
            onAccept={() => {
                handleUserPreference('accepted');
            }}
            onDecline={() => {
                handleUserPreference('rejected');
            }}
            style={{ 
                background: "white",
                position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "450px",
                width: "90%",
                borderRadius: "12px",
                padding: "20px",
                boxShadow: "0 2px 20px rgba(0, 0, 0, 0.1)",
                color: "#000",
                zIndex: 10000,
                border: "1px solid #eee",
                display: cookiesAccepted ? 'none' : 'block'
            }}
            buttonStyle={{ 
                background: "#000",
                color: "white",
                fontSize: "14px",
                borderRadius: "25px",
                padding: "8px 24px",
                border: "none",
                cursor: "pointer",
                minWidth: "100px",
                fontWeight: "500"
            }}
            declineButtonStyle={{
                background: "#f5f5f5",
                color: "#000",
                fontSize: "14px",
                borderRadius: "25px",
                padding: "8px 24px",
                border: "none",
                cursor: "pointer",
                minWidth: "100px",
                fontWeight: "500"
            }}
            buttonWrapperStyle={{ 
                display: 'flex', 
                justifyContent: 'flex-end',
                gap: '10px',
                padding: 0,
                marginTop: '15px'
            }}
            containerStyle={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                margin: 0
            }}
        >
            <div style={{ display: 'flex', gap: '20px', alignItems: 'flex-start' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}>
                    <h3 style={{ 
                        margin: '0', 
                        fontSize: '18px', 
                        fontWeight: '600' 
                    }}>
                        Cookies
                    </h3>
                    <p style={{ 
                        margin: 0, 
                        fontSize: '14px', 
                        lineHeight: '1.5', 
                        color: '#666' 
                    }}>
                        {language === "fr" ? 
                            "Nous utilisons des cookies pour améliorer votre expérience sur notre site. En continuant à naviguer, vous acceptez notre utilisation des cookies." 
                            : 
                            "We use cookies to improve your experience on our site. By continuing to browse, you accept our use of cookies."}
                    </p>
                    <a 
                        href="/cookies" 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{ 
                            color: '#000',
                            fontSize: '14px',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {language === "fr" ? 
                            "En savoir plus sur notre politique de cookies" 
                            : 
                            "Learn more about our cookie policy"}
                    </a>
                </div>
            </div>
        </CookieConsent>
    );
}

export default CustomCookieConsent;
