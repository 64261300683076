import React from 'react';
import '../styles/Home.css';
import scanImage from '../Assets/scan.svg';
import orderImage from '../Assets/order.svg';
import cookingImage from '../Assets/cooking.svg';
import eatImage from '../Assets/payment.svg';
import lowcostImage from '../Assets/lowcost.svg';
import teamImage from '../Assets/team.svg';
import modernImage from '../Assets/modern.svg';
import solutionImage from '../Assets/solution.svg';
import phoneImage from '../Assets/phone.svg';

function Home() {
    return (
        <div className="home-container">
            <header className="hero-section">
                <h1 className="hero-title">Bienvenue chez SkanEat</h1>
                <p className="hero-subtitle">Simplifiez la prise de commande, améliorez l'expérience client et optimisez votre service !</p>
                <p className="hero-description">SkanEat révolutionne la gestion des commandes dans votre établissement. Grâce à nos outils connectés (QR Code et puce NFC),
                    vos clients commandent facilement depuis leur smartphone et bénéficient d'un service rapide et fluide. 
                    De votre côté, vous gagnez en efficacité, évitez les erreurs et suivez chaque commande en temps réel.</p>
                <p className="hero-description-2">Répondez aux attentes modernes tout en augmentant votre efficacité et proposez un nouveau parcours à vos clients : plus rapide, plus simple et plus sûr !</p>
            </header>

            <section className="features-section">
                <h2 className="section-title">Comment ça marche ?</h2>
                <div className="features-container">
                    <div className="feature">
                        <img src={scanImage} alt="Scan QR Code" className="feature-image" />
                        <h3>Étape 1 - Scannez et accédez au menu</h3>
                        <p>Le client scanne le QR Code ou utilise la puce NFC placée sur sa table, un chevalet ou un flyer.
                        En un instant, il accède au menu interactif du restaurant directement sur son smartphone.</p>
                    </div>
                    <div className="feature">
                        <img src={orderImage} alt="Consultation et commande" className="feature-image" />
                        <h3>Étape 2 - Personnalisez et validez votre commande</h3>
                        <p>Le client entre son nom et son adresse email avant de commencer sa commande.
                        Depuis le menu, le client choisit ses plats, boissons et options (cuisson, suppléments, etc.).</p>
                    </div>
                    <div className="feature">
                        <img src={cookingImage} alt="Commande en cuisine" className="feature-image" />
                        <h3>Étape 3 - Gestion en temps réel</h3>
                        <p>Une fois la commande passée, la commande est envoyé en cuisine et le client accède à un suivi en direct : 
                        il voit le statut de chaque plat (en préparation, prêt à être servi) et peut ajouter d'autres articles à sa commande si besoin.</p>
                    </div>
                    <div className="feature">
                        <img src={eatImage} alt="Commande à la table" className="feature-image" />
                        <h3>Étape 4 - Payez facilement</h3>
                        <p>Le client choisit de payer au comptoir ou directement sur son téléphone via Stripe, Google Pay ou Apple Pay.
                        Une fois le paiement effectué, un ticket numérique peut être envoyé par email pour une expérience écologique et moderne.</p>
                    </div>
                </div>
            </section>

            <section className="why-choose-section">
                <h2 className="section-title">Pourquoi choisir SkanEat ?</h2>
                <div className="why-choose-container">
                    <div className="why-choose-item">
                        <img src={phoneImage} alt="Accès sans contact" className="why-image" />
                        <h3>Fluidité pour vos clients</h3>
                        <p>Avec une commande rapide et intuitive, vos clients gagnent du temps et profitent d’un service de qualité. 
                        Plus besoin d'attendre un serveur pour commander ou recevoir la carte.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={teamImage} alt="Gain de temps" className="why-image" />
                        <h3>Efficacité pour votre équipe</h3>
                        <p>Un tableau de bord intelligent aide vos serveurs à prioriser les tâches et évite les erreurs en salle comme en cuisine.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={lowcostImage} alt="Satisfaction Client" className="why-image" />
                        <h3>Réduction des coûts et écoresponsabilité</h3>
                        <p>Éliminez les impressions inutiles grâce aux tickets numériques envoyés par mail et réduisez les erreurs qui coûtent cher.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={modernImage} alt="Satisfaction Client" className="why-image" />
                        <h3>Modernité et satisfaction</h3>
                        <p>Offrez une image moderne et innovante de votre établissement tout en augmentant la satisfaction client.</p>
                    </div>
                    <div className="why-choose-item">
                        <img src={solutionImage} alt="Satisfaction Client" className="why-image" />
                        <h3>Une solution tout-en-un</h3>
                        <p>De la commande à la gestion complète de l’établissement, SkanEat vous accompagne dans tous les aspects de votre activité.</p>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
