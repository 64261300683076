import React, { useState, useEffect } from 'react';
import '../styles/FAQ.css';

function FAQ() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState(null);

    const faqs = [
        {
            question: "Le QR code qu'est-ce que c'est ?",
            answer: "Le QR code est un tag lisible par les téléphones portables et les tablettes. Sa lecture se fait en ouvrant l’appareil photo du mobile ce qui permet d’accéder directement à une page web présentant la carte de votre restaurant ou bar !"
        },
        {
            question: "La puce NFC qu'est-ce que c'est ?",
            answer: "La puce NFC est une petite puce lisible par les téléphones portables IOS comme Android. Sa lecture se fait en approchant le smartphone près de la puce ce qui permet d’accéder directement à une page web présentant la carte de votre restaurant ou bar !"
        },
        {
            question: "Comment fonctionne la configuration initiale de SkanEat ?",
            answer: "Une fois que vous avez souscrit à l’un de nos packs, vous recevrez un guide et une présentation sur place par notre équipe pour configurer votre menu en ligne. Vous pourrez personnaliser chaque aspect (articles, prix, photos) et accéder à votre tableau de bord en quelques clics."
        },
        {
            question: "Comment créer un menu QR Code ?",
            answer: "Prenez rendez-vous avec notre équipe ou demandez un devis en ligne pour votre établissement. Nous assurons le déplacement sur place pour mettre en place ensemble votre formulaire de commande en ligne."
        },
        {
            question: "Afficher les allergènes ?",
            answer: "Vous pouvez sélectionner les allergènes associés à chaque plat et les indiquer sur votre menu. Ainsi vos clients sont mieux informés quand ils lisent votre menu."
        },
        {
            question: "Adapter les prix ?",
            answer: "Il est possible d'afficher des prix différents en fonction des horaires que vous définissez (midi et soir, semaine et weekend, happy hour) disponible dans le pack premium."
        },
        {
            question: "Mon établissement est petit. SkanEat est-il adapté pour moi ?",
            answer: "Absolument ! Que vous ayez un petit café ou un grand restaurant, SkanEat est modulable et s’adapte à vos besoins spécifiques."
        },
        {
            question: "Personnaliser la charte graphique ?",
            answer: "La charte graphique est entièrement personnalisable : logo, page d'accueil, arrière plan, couleur et police de caractère. Nous nous occupons d'intégrer votre charte graphique à la mise en place de votre menu."
        },
        {
            question: "Puis-je ajouter des options ou des personnalisations pour les plats ?",
            answer: "Oui, le menu interactif permet aux clients de sélectionner des options (exemple : cuisson, suppléments, allergies, etc.) directement depuis leur smartphone."
        },
        {
            question: "Est-il possible de limiter certains accès pour mes employés ?",
            answer: "Oui, SkanEat propose différents niveaux d’accès : serveurs, cuisiniers, barmans, administrateurs. Vous contrôlez qui peut accéder à quelles fonctionnalités."
        },
        {
            question: "Mes données sont-elles sécurisées ?",
            answer: "Tout à fait. Nous utilisons un chiffrement de haut niveau pour protéger les données de votre établissement et celles de vos clients."
        },
        {
            question: "Combien de temps faut-il pour former mon équipe à utiliser SkanEat ?",
            answer: "SkanEat est conçu pour être simple et intuitif. Une formation rapide d’une heure suffit généralement pour que vos employés soient à l’aise."
        },
        {
            question: "Puis-je intégrer mon système de caisse actuel avec SkanEat ?",
            answer: "Nous proposons des solutions d’intégration avec certains systèmes de caisse. Contactez notre support pour vérifier la compatibilité avec le vôtre."
        },
        {
            question: "Que faire si un client n’a pas de smartphone ?",
            answer: "Pas de problème ! Vous pouvez prendre la commande manuellement et l’ajouter directement dans le tableau de bord SkanEat."
        },
        {
            question: "Comment gérer les annulations ou les modifications de commande ?",
            answer: "Depuis le tableau de bord, les serveurs peuvent modifier ou annuler une commande en temps réel, avec des mises à jour automatiques pour le client."
        },
        {
            question: "SkanEat est-il compatible avec tous les smartphones ?",
            answer: "Oui, SkanEat fonctionne avec la majorité des appareils modernes équipés d’un navigateur et d’un lecteur QR Code/NFC, que ce soit Android ou iOS."
        },
        {
            question: "Que se passe-t-il si un article est en rupture pendant le service ?",
            answer: "L’administrateur peut désactiver l’article en quelques secondes depuis le tableau de bord. Le changement est immédiatement visible sur le menu des clients."
        },
        {
            question: "Puis-je utiliser mon propre matériel (tablettes, imprimantes) ?",
            answer: "Oui, SkanEat est compatible avec la plupart des appareils standards. Vous pouvez également consulter notre liste d’équipements recommandés."
        },
        {
            question: "Mon menu change régulièrement, est-ce facile à gérer ?",
            answer: "Tout à fait ! Vous pouvez modifier, ajouter ou supprimer des plats en temps réel depuis l’interface admin, même en plein service."
        },
        {
            question: "Combien coûte l’installation de QR Codes et de puces NFC ?",
            answer: "Nous fournissons des QR Codes personnalisés gratuitement. Pour les puces NFC, des packs sont disponibles à prix réduit lors de votre souscription."
        },
        {
            question: "Quels avantages financiers puis-je obtenir avec SkanEat ?",
            answer: "SkanEat vous aide à réduire les coûts de papier (tickets numériques) et à mieux organiser vos finances grâce à des rapports détaillés, facilitant ainsi votre comptabilité."
        }
    ];

    const filteredFaqs = faqs.filter(faq =>
        faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        faq.answer.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openPopup = (faq) => setSelectedQuestion(faq);
    const closePopup = () => setSelectedQuestion(null);

    useEffect(() => {
        document.body.classList.toggle('no-scroll', !!selectedQuestion);
    }, [selectedQuestion]);

    const highlightText = (text, searchTerm) => {
        if (!searchTerm) return text;
        const regex = new RegExp(`(${searchTerm})`, 'gi');
        return text.split(regex).map((part, index) => 
            regex.test(part) ? <span key={index} className="highlight">{part}</span> : part
        );
    };

    return (
        <div className="faq-section">
            <h2 className="faq-title" >FAQ</h2>
            <input
                type="text"
                placeholder="Rechercher..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <div className="faq-container">
                {filteredFaqs.length > 0 ? (
                    filteredFaqs.map((faq, index) => (
                        <div className="faq-item" key={index} onClick={() => openPopup(faq)}>
                            <h3 >{highlightText(faq.question, searchTerm)}</h3>
                        </div>
                    ))
                ) : (
                    <p className="no-results" >Aucun résultat trouvé pour votre recherche.</p>
                )}
            </div>

            {selectedQuestion && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <h3 >{highlightText(selectedQuestion.question, searchTerm)}</h3>
                        <p >{highlightText(selectedQuestion.answer, searchTerm)}</p>
                        <button className="close-btn"  onClick={closePopup}>Fermer</button>
                    </div>
                </div>
            )}

            <p className="contact-message">
                <a href="/contact" target='_blank' className="contact-link" >Pour plus de renseignements, contactez-nous</a>.
            </p>
        </div>
    );
}

export default FAQ;
