import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../styles/Contact.css';

emailjs.init('T-pY9-uH4qzIB7dph');

function Contact() {
    const [countries, setCountries] = useState([]);
    const [formData, setFormData] = useState({
        restoName: '',
        firstName: '',
        email: '',
        telephone: '',
        ville: '',
        pays: '',
        commentaire: ''
    });
    const [countryCode, setCountryCode] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCountries = async (retryCount = 0) => {
            try {
                const response = await fetch('https://restcountries.com/v3.1/all', {
                    headers: {
                        'Accept': 'application/json',
                        'Cache-Control': 'no-cache'
                    },
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                const formattedCountries = data
                    .map(country => ({
                        name: country.translations.fra?.common || country.name.common,
                        code: country.cca2,
                        dialCode: country.idd.root ? `${country.idd.root}${country.idd.suffixes?.[0] || ''}` : ''
                    }))
                    .filter(country => country.dialCode)
                    .sort((a, b) => a.name.localeCompare(b.name));

                localStorage.setItem('countriesList', JSON.stringify(formattedCountries));
                setCountries(formattedCountries);
                
                const france = formattedCountries.find(country => country.code === 'FR');
                if (france) {
                    setFormData(prev => ({
                        ...prev,
                        pays: france.name,
                        telephone: france.dialCode
                    }));
                    setCountryCode(france.dialCode);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des pays:', error);
                
                const cachedCountries = localStorage.getItem('countriesList');
                if (cachedCountries) {
                    const parsedCountries = JSON.parse(cachedCountries);
                    setCountries(parsedCountries);
                    
                    const france = parsedCountries.find(country => country.code === 'FR');
                    if (france) {
                        setFormData(prev => ({
                            ...prev,
                            pays: france.name,
                            telephone: france.dialCode
                        }));
                        setCountryCode(france.dialCode);
                    }
                } else if (retryCount < 2) {
                    setTimeout(() => fetchCountries(retryCount + 1), 1000);
                    return;
                } else {
                    const fallbackCountries = [
                        { name: "France", code: "FR", dialCode: "+33" },
                        { name: "Belgique", code: "BE", dialCode: "+32" },
                    ];
                    setCountries(fallbackCountries);
                    
                    const france = fallbackCountries[0];
                    setFormData(prev => ({
                        ...prev,
                        pays: france.name,
                        telephone: france.dialCode
                    }));
                    setCountryCode(france.dialCode);
                }
            } finally {
                setIsLoading(false);
            }
        };

        const cachedCountries = localStorage.getItem('countriesList');
        if (cachedCountries) {
            const parsedCountries = JSON.parse(cachedCountries);
            setCountries(parsedCountries);
            const france = parsedCountries.find(country => country.code === 'FR');
            if (france) {
                setFormData(prev => ({
                    ...prev,
                    pays: france.name,
                    telephone: france.dialCode
                }));
                setCountryCode(france.dialCode);
            }
            setIsLoading(false);
        }

        fetchCountries();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCountryChange = (e) => {
        const selectedCountry = countries.find(country => country.code === e.target.value);
        setFormData({
            ...formData,
            pays: selectedCountry.name,
            telephone: selectedCountry.dialCode
        });
        setCountryCode(selectedCountry.dialCode);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const webhookURL = 'https://discord.com/api/webhooks/1308510644344983644/d49KW4O6VJnZZY6NNV7wJAzzgloji4ibBfyHsZrY926mnZHs6zuJAdXSTSLs1N3g7av8';

        const message = {
            content: `Nouveau contact:\nNom d'établissement: ${formData.restoName}\nNom et prénom: ${formData.firstName}\nEmail: ${formData.email}\nVille: ${formData.ville}\nPays: ${formData.pays}\nTéléphone: ${formData.telephone}\nCommentaire: ${formData.commentaire}`
        };

        let success = true;

        try {
            const response = await fetch(webhookURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(message)
            });

            if (!response.ok) {
                success = false;
            }

            const emailData = {
                restoName: formData.restoName,
                firstName: formData.firstName,
                email: formData.email,
                ville: formData.ville,
                pays: formData.pays,
                telephone: formData.telephone,
                commentaire: formData.commentaire
            };

            await emailjs.send('service_yuq4j4t', 'template_4lvb5zy', emailData);
        } catch (error) {
            success = false;
        }

        if (success) {
            alert('Données envoyées avec succès');
            
            const form = document.querySelector('.contact-form');
            form.reset();
            
            setFormData({
                restoName: '',
                firstName: '',
                email: '',
                telephone: countryCode,
                ville: '',
                pays: 'France',
                commentaire: ''
            });
        } else {
            alert('Problème lors de l\'envoi des données');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="contact-form">
            <h2>Contact</h2>
            <div className="form-row">
                <input name="restoName" placeholder="Votre nom d'établissement*" onChange={handleChange} required  />
                <input name="firstName" placeholder="Votre nom et prénom*" onChange={handleChange} required  />
            </div>
            <div className="form-row">
                <select 
                    name="pays" 
                    onChange={handleCountryChange} 
                    required 
                    value={countries.find(country => country.dialCode === countryCode)?.code || ''}
                    disabled={isLoading}
                >
                    {isLoading ? (
                        <option>Chargement des pays...</option>
                    ) : (
                        countries.map(country => (
                            <option key={country.code} value={country.code}>
                                {country.name} ({country.dialCode})
                            </option>
                        ))
                    )}
                </select>
                <input
                    name="telephone"
                    type="tel"
                    placeholder="Votre téléphone*"
                    value={formData.telephone}
                    onChange={handleChange}
                    required
                    
                />
            </div>
            <div className="form-row">
                <input name="ville" placeholder="Ville*" onChange={handleChange} required  />
                <input name="email" type="email" placeholder="Votre email*" onChange={handleChange} required  />
            </div>
            <div className="form-row">
                <textarea className="wide" name="commentaire" placeholder="Demande / Démo / Questions ?*" onChange={handleChange} required ></textarea>
            </div>
            <button type="submit" className="submitBtn" >Envoyer</button>
        </form>
    );
};

export default Contact;
