import React from 'react';
import '../styles/CGV.css';

function CGV() {
    return (
        <div className="cgv-container">
            <h1>Conditions Générales de Vente (CGV)</h1>
            
            <section>
                <h2>1. Introduction</h2>
                <p>
                    Bienvenue sur SkanEat ! Ces Conditions Générales de Vente (CGV) régissent l'utilisation de notre site web
                    et l'achat de nos applications web destinées aux services de restauration. En accédant à notre site et
                    en effectuant un achat, vous acceptez d'être lié par ces CGV.
                </p>
            </section>
            
            <section>
                <h2>2. Définitions</h2>
                <ul>
                    <li><strong>SkanEat :</strong> Société éditrice du site www.skaneat.com et prestataire des services proposés.</li>
                    <li><strong>Client :</strong> Toute personne physique ou morale souhaitant bénéficier des services de SkanEat.</li>
                    <li><strong>Services :</strong> Création et livraison d'applications web personnalisées, installation des QR codes et outils associés.</li>
                    <li><strong>Devis :</strong> Document contractuel précisant les prestations, tarifs et conditions spécifiques convenues avec le Client.</li>
                </ul>
            </section>

            <section>
                <h2>3. Packs Proposés</h2>
                <p>SkanEat propose des applications web conçues spécifiquement pour les services de restauration. Les
                caractéristiques essentielles des produits sont présentées sur notre site dans chaque fiche produit :</p>
                <ul>
                    <li><strong>Pack Basique :</strong> Service minimaliste avec fonctionnalités essentielles.</li>
                    <li><strong>Pack Standard :</strong> Service intermédiaire avec des options supplémentaires.</li>
                    <li><strong>Pack Premium :</strong> Service complet avec toutes les fonctionnalités avancées.</li>
                    <li><strong>Pack Sur Mesure :</strong> Service entièrement personnalisé, proposé uniquement sur devis.</li>
                </ul>
                <p>
                    Les packs Basique, Standard et Premium incluent un engagement minimum de 12 mois avec un tarif mensuel fixe.
                    Le Pack Sur Mesure est conçu sur mesure et fait l’objet d’un devis spécifique.
                </p>
            </section>

            <section>
                <h2>4. Prix</h2>
                <p>
                    Les prix des produits sont indiqués en euros toutes taxes comprises (TTC). SkanEat se réserve le droit de
                    modifier ses prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au
                    moment de la validation de la commande.
                </p>
            </section>

            <section>
                <h2>5. Processus de Commande</h2>
                <p>Le processus de commande se déroule comme suit :</p>
                <ol>
                    <li>Prise de contact via notre site ou nos moyens de communication.</li>
                    <li>Rédaction du devis détaillant les prestations, tarifs, et délais.</li>
                    <li>Validation du devis par le Client (signature manuscrite ou numérique).</li>
                    <li>Paiement initial par virement bancaire.</li>
                    <li>Exécution du service et livraison.</li>
                </ol>
            </section>

            <section>
                <h2>6. Délais de Réalisation</h2>
                <p>
                    Une fois le devis validé et le paiement initial reçu, SkanEat s’engage à livrer l’application dans un délai maximal
                    de <strong>1 mois</strong>, sous réserve de la complexité du projet et de la charge de travail.
                </p>
            </section>

            <section>
                <h2>7. Livraison</h2>
                <p>SkanEat livre un produit clé en main incluant :</p>
                <ul>
                    <li>L’application web finalisée.</li>
                    <li>L’installation des QR codes sur les tables du restaurant.</li>
                    <li>La formation du personnel à l’utilisation de l’application (si spécifié dans le devis).</li>
                </ul>
            </section>
            
            <section>
                <h2>8. Acceptation des Conditions Générales de Vente</h2>
                <p>
                    En utilisant notre site et en passant commande, le Client reconnaît avoir lu, compris et accepté sans réserve
                    les présentes CGV.
                </p>
            </section>
            
            <section>
                <h2>9. Tarifs et Modalités de Paiement</h2>
                <p>
                    Les tarifs des packs Basique, Standard et Premium incluent un forfait initial et un abonnement mensuel sur
                    une période de 12 mois. Le Pack Sur Mesure est facturé en fonction des besoins spécifiques définis dans le devis.
                </p>
                <p>
                    <strong>Paiement :</strong> Tous les paiements s'effectuent par virement bancaire. Le paiement initial doit être
                    réalisé avant le début des travaux, et les mensualités sont dues conformément aux conditions spécifiées dans le devis.
                </p>
            </section>
            
            <section>
                <h2>10. Droit de rétractation</h2>
                <p>
                    Conformément aux dispositions légales en vigueur, le Client dispose d'un délai de quatorze (14) jours à
                    compter de la réception de l'application web pour exercer son droit de rétractation sans avoir à justifier
                    de motifs ni à payer de pénalités. Pour exercer ce droit, le Client doit notifier SkanEat de sa décision de
                    rétractation par une déclaration dénuée d’ambiguïté.
                </p>
            </section>

            <section>
                <h2>11. Engagements et Obligations du Client</h2>
                <p>
                    Le Client s'engage à fournir toutes les informations nécessaires à la bonne réalisation des services et à
                    respecter les échéances de paiement convenues.
                </p>
            </section>
            
            <section>
                <h2>12. Garantie et Support</h2>
                <p>
                    SkanEat garantit la conformité des applications livrées aux spécifications validées dans le devis. Un support technique
                    est disponible pour toute assistance liée à l’utilisation de l’application.
                </p>
            </section>
            
            <section>
                <h2>13. Responsabilité</h2>
                <p>
                    SkanEat ne pourra être tenue responsable des dommages indirects qui pourraient survenir du fait de l'achat
                    des produits. La responsabilité de SkanEat au titre des présentes CGV ne pourra excéder une somme égale aux
                    montants payés ou payables lors de la transaction à l'origine de ladite responsabilité.
                </p>
            </section>
            
            <section>
                <h2>14. Protection des Données Personnelles</h2>
                <p>
                    SkanEat s'engage à protéger les données personnelles de ses Clients. Les informations collectées sont
                    nécessaires à la gestion des commandes et aux relations commerciales. Conformément à la loi Informatique et
                    Libertés, le Client dispose d'un droit d'accès, de rectification et d'opposition aux données le concernant.
                </p>
            </section>
            
            <section>
                <h2>15. Propriété Intellectuelle</h2>
                <p>
                    Tous les éléments du site www.skaneat.com, qu'ils soient visuels ou sonores, y compris la technologie
                    sous-jacente, sont protégés par le droit d'auteur, des marques ou des brevets. Ils sont la propriété
                    exclusive de SkanEat. Les applications livrées et les QR codes restent la propriété de SkanEat jusqu’à paiement complet des sommes dues.
                </p>
            </section>

            <section>
                <h2>16. Résiliation</h2>
                <p>
                    En cas de manquement grave par l’une des parties, une résiliation peut être effectuée après mise en demeure restée
                    infructueuse pendant 15 jours. La résiliation entraîne l'exigibilité immédiate des sommes dues.
                </p>
            </section>
            
            <section>
                <h2>17. Loi Applicable et Litiges</h2>
                <p>
                    Les présentes CGV sont régies par le droit français. En cas de litige, les parties s'engagent à rechercher une
                    solution amiable avant de saisir les juridictions compétentes.
                </p>
            </section>
            
            <section>
                <h2>18. Modification des Conditions Générales de Vente</h2>
                <p>
                    SkanEat se réserve le droit de modifier les présentes CGV à tout moment. Les nouvelles conditions générales
                    seront applicables dès leur publication sur le site.
                </p>
            </section>
        </div>
    );
}

export default CGV;
