import React from 'react';
import '../styles/CookiesPage.css';
import { useCookieConsent } from './CookieConsentContext';

function Cookies() {
    const { resetCookieConsent } = useCookieConsent();

    const handleResetCookies = () => {
        resetCookieConsent();
    };

    return (
        <div className="cookies-container">
            <h1>Politique Relative à l'Utilisation des Cookies</h1>

            <section>
                <h2>1. Introduction</h2>
                <p>
                    Cette politique explique comment SkanEat utilise les cookies et technologies similaires sur son site web.
                    En naviguant sur notre site, vous acceptez l'utilisation des cookies conformément à cette politique, sauf si
                    vous configurez votre navigateur pour les refuser.
                </p>
            </section>

            <section>
                <h2>2. Qu'est-ce qu'un Cookie ?</h2>
                <p>
                    Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil (ordinateur, tablette, smartphone)
                    lorsque vous visitez un site web. Ils permettent au site de reconnaître votre appareil et de mémoriser certaines
                    informations, comme vos préférences ou vos actions précédentes.
                </p>
            </section>

            <section>
                <h2>3. Types de Cookies Utilisés</h2>
                <p>Nous utilisons les types de cookies suivants :</p>
                <ul>
                    <li><strong>Cookies nécessaires :</strong> Ces cookies sont essentiels pour permettre le fonctionnement de base
                        du site. Ils ne peuvent pas être désactivés.
                    </li>
                    <li><strong>Cookies fonctionnels :</strong> Ces cookies améliorent la fonctionnalité et la personnalisation du site.
                        Par exemple, ils mémorisent vos préférences linguistiques.
                    </li>
                    <li><strong>Cookies analytiques :</strong> Ces cookies nous aident à comprendre comment les utilisateurs interagissent
                        avec notre site, en collectant des données anonymes.
                    </li>
                    <li><strong>Cookies publicitaires :</strong> Ces cookies sont utilisés pour afficher des publicités pertinentes
                        et personnalisées sur d'autres sites web.
                    </li>
                </ul>
            </section>

            <section>
                <h2>4. Pourquoi Utilisons-Nous des Cookies ?</h2>
                <p>Nous utilisons les cookies pour :</p>
                <ul>
                    <li>Améliorer la performance et la sécurité de notre site.</li>
                    <li>Mémoriser vos préférences et paramètres.</li>
                    <li>Analyser le trafic et l'utilisation de notre site pour en améliorer les fonctionnalités.</li>
                    <li>Proposer des contenus personnalisés ou des publicités ciblées.</li>
                </ul>
            </section>

            <section>
                <h2>5. Gestion des Cookies</h2>
                <p>
                    Vous pouvez gérer vos préférences en matière de cookies en utilisant les options suivantes :
                </p>
                <ul>
                    <li><strong>Paramètres de votre navigateur :</strong> Vous pouvez configurer votre navigateur pour accepter, refuser
                        ou supprimer des cookies. Veuillez noter que le blocage des cookies nécessaires peut limiter certaines fonctionnalités
                        du site.
                    </li>
                    <li><strong>Outils tiers :</strong> Vous pouvez utiliser des outils comme <a href="https://www.youronlinechoices.com/"
                        target="_blank" rel="noopener noreferrer">YourOnlineChoices</a> pour gérer vos préférences en matière de publicité en ligne.
                    </li>
                </ul>
            </section>

            <section>
                <h2>6. Cookies Tiers</h2>
                <p>
                    Certains cookies utilisés sur notre site sont fournis par des tiers, tels que des outils analytiques ou publicitaires.
                    Ces tiers sont responsables de la gestion des cookies qu'ils placent. Nous vous invitons à consulter leurs politiques
                    de confidentialité pour plus d'informations.
                </p>
            </section>

            <section>
                <h2>7. Consentement</h2>
                <p>
                    Lors de votre première visite sur notre site, une bannière vous informe de l'utilisation des cookies et vous propose
                    d'accepter ou de personnaliser vos préférences. Vous pouvez modifier vos choix à tout moment en accédant aux paramètres
                    des cookies sur notre site.
                </p>
            </section>

            <section>
                <h2>8. Modifications de la Politique</h2>
                <p>
                    Nous nous réservons le droit de mettre à jour cette politique en fonction de l'évolution de la réglementation ou de
                    nos pratiques. Toute modification sera publiée sur cette page avec une date de mise à jour.
                </p>
            </section>

            <section>
                <h2>9. Contact</h2>
                <p>
                    Si vous avez des questions ou des préoccupations concernant notre utilisation des cookies, contactez-nous via le <a href="/contact">formulaire de contact</a>.
                </p>
            </section>

            <section className="cookies-actions">
                <button 
                    onClick={handleResetCookies}
                    className="reset-cookies-button"
                    style={{
                        background: "#800000",
                        color: "white",
                        fontSize: "16px",
                        borderRadius: "25px",
                        padding: "12px 30px",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: "500",
                        marginBottom: "20px",
                        transition: "background-color 0.3s ease"
                    }}
                    onMouseOver={(e) => e.target.style.background = "#600000"}
                    onMouseOut={(e) => e.target.style.background = "#800000"}
                >
                    Réinitialiser mes préférences de cookies
                </button>
            </section>

            <section className="back-section" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '40px' }}>
                <button 
                    onClick={() => window.location.href = '/'}
                    style={{
                        background: "#800000",
                        color: "white",
                        fontSize: "16px",
                        borderRadius: "25px",
                        padding: "12px 30px",
                        border: "none",
                        cursor: "pointer",
                        fontWeight: "500",
                        transition: "background-color 0.3s ease"
                    }}
                    onMouseOver={(e) => e.target.style.background = "#600000"}
                    onMouseOut={(e) => e.target.style.background = "#800000"}
                >
                    Retour à l'accueil
                </button>
            </section>
        </div>
    );
}

export default Cookies;
