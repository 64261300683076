import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/NotFound.css';

function NotFound() {
    return (
        <div className="not-found-container">
            <div className="not-found-content">
                <h1 className="error-code">
                    4<span className="qr-code"></span>4
                </h1>
                <h2 className="error-message">Oups ! Page non trouvée</h2>
                <p className="error-description">
                    Il semblerait que nous n'ayont aucun souvenir de la page que vous recherchez.
                </p>
                <div className="error-actions">
                    <Link to="/" className="home-button">
                        Retourner à l'accueil
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;