import React, { createContext, useContext, useState } from 'react';

const CookieConsentContext = createContext();

export function CookieConsentProvider({ children }) {
    const [showCookieConsent, setShowCookieConsent] = useState(true);
    const [cookiePreferences, setCookiePreferences] = useState({
        necessary: true,
        functional: false,
        analytics: false,
        advertising: false
    });

    const resetCookieConsent = () => {
        const cookies = document.cookie.split(';');
        
        for (let cookie of cookies) {
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        }

        setCookiePreferences({
            necessary: true,
            functional: false,
            analytics: false,
            advertising: false
        });
        setShowCookieConsent(true);

        window.location.href = '/';
    };

    const value = {
        showCookieConsent,
        setShowCookieConsent,
        cookiePreferences,
        setCookiePreferences,
        resetCookieConsent
    };

    return (
        <CookieConsentContext.Provider value={value}>
            {children}
        </CookieConsentContext.Provider>
    );
}

export function useCookieConsent() {
    const context = useContext(CookieConsentContext);
    if (context === undefined) {
        throw new Error('useCookieConsent must be used within a CookieConsentProvider');
    }
    return context;
} 