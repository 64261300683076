import React from 'react';
import '../styles/Features.css';
import featureImage1 from '../Assets/scan.svg';
import featureImage2 from '../Assets/waiter.svg';
import featureImage3 from '../Assets/cook.svg';
import featureImage4 from '../Assets/admin.svg';

function Features() {
    return (
        <div className="features-container">
            <h2 className="features-title">Les Fonctionnalités de SkanEat</h2>
            <p className="features-description">
                Découvrez comment SkanEat révolutionne l'expérience de commande pour vos clients et simplifie la gestion de votre restaurant.
            </p>

            <div className="features-list">
                <div className="feature-item">
                    <img src={featureImage1} alt="Commande via QR Code ou NFC" className="feature-image" />
                    <h3>Pour vos clients</h3>
                    <p>
                    Avec SkanEat, vos clients profitent d’une commande simple, rapide et intuitive depuis leur smartphone. 
                    En scannant un QR Code ou en utilisant une puce NFC, ils accèdent à votre menu interactif. 
                    Ils peuvent personnaliser leur commande et suivre le statut de leur commande en temps réel. 
                    Le paiement est flexible : directement sur leur téléphone ou au comptoir, avec une option d’envoi de ticket par email pour une approche écologique.
                    </p>
                </div>

                <div className="feature-item">
                    <img src={featureImage2} alt="Gestion du menu en temps réel" className="feature-image" />
                    <h3>Pour les serveurs</h3>
                    <p>
                    SkanEat facilite le travail des serveurs grâce à un tableau de bord intelligent. 
                    Ils peuvent visualiser toutes les commandes, savoir quelles tables sont occupées et être informés en temps réel des plats prêts en cuisine. 
                    Ils gèrent également les paiements au comptoir et peuvent imprimer des tickets si nécessaire.
                    </p>
                </div>

                <div className="feature-item">
                    <img src={featureImage3} alt="Suivi des Commandes et Statistiques" className="feature-image" />
                    <h3>Pour les cuisiniers et barmans</h3>
                    <p>
                    Les cuisiniers et barmans ont accès à une liste claire des préparations à réaliser, organisée par catégorie (entrées, plats, desserts, boissons). 
                    Une fois un plat ou une boisson prêt, ils notifient les serveurs pour un service rapide et fluide.
                    </p>
                </div>

                <div className="feature-item">
                    <img src={featureImage4} alt="Suivi des Commandes et Statistiques" className="feature-image" />
                    <h3>Pour l’administrateur</h3>
                    <p>
                    L’administrateur a un contrôle total sur son établissement via une interface dédiée. 
                    Il peut gérer le menu en temps réel, activer ou désactiver des articles en rupture, et analyser les performances de son restaurant grâce à des statistiques détaillées. 
                    Il dispose également d’outils pour gérer le personnel (horaires, heures supplémentaires, créer ou supprimer des comptes de personnel) 
                    et peut accéder aux avis des clients. Les rapports financiers et les factures hebdomadaires ou mensuelles facilitent la comptabilité.
                    </p>
                </div>
            </div>

            <div className="contact-section">
                <h3>Envie d'en savoir plus ?</h3>
                <p>
                    Contactez-nous via notre <a href="/contact">formulaire de contact</a> pour plus d'informations sur
                    nos fonctionnalités et comment SkanEat peut améliorer votre restaurant !
                </p>
            </div>
        </div>
    );
}

export default Features;
