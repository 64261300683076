import React, { useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../styles/Partners.css';
import L from 'leaflet';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const partners = [
    {
        id: 1,
        name: "Restaurant Paris",
        position: [48.8566, 2.3522],
        rating: 4.5,
        image: "https://avatars.githubusercontent.com/u/45422990?s=400&u=b8d6f8115202cb8972277cdef54c834239d58d69&v=4",
        description: "Restaurant gastronomique au cœur de Paris",
        address: "123 Avenue des Champs-Élysées, Paris"
    },
    {
        id: 2,
        name: "Bistro Lyon",
        position: [45.7578, 4.8320],
        rating: 5,
        image: "https://skaneat.com/static/media/scan.af07e10c9812c640a089b118cf830303.svg",
        description: "Cuisine traditionnelle lyonnaise",
        address: "45 Rue Mercière, Lyon"
    },
    {
        id: 3,
        name: "Café Marseille",
        position: [43.2965, 5.3698],
        rating: 4,
        image: "https://skaneat.com/static/media/scan.af07e10c9812c640a089b118cf830303.svg",
        description: "Spécialités méditerranéennes",
        address: "89 Quai du Port, Marseille"
    },
    {
        id: 4,
        name: "Brasserie Bordeaux",
        position: [44.8378, -0.5792],
        rating: 4.8,
        image: "https://skaneat.com/static/media/scan.af07e10c9812c640a089b118cf830303.svg",
        description: "Cuisine du Sud-Ouest et grands vins",
        address: "12 Place de la Bourse, Bordeaux"
    },
];

const RatingStars = ({ rating }) => {
    const [showRating, setShowRating] = useState(false);
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 1; i <= 5; i++) {
        if (i <= fullStars) {
            stars.push(<FaStar key={i} className="text-yellow-400" />);
        } else if (i === fullStars + 1 && hasHalfStar) {
            stars.push(<FaStarHalfAlt key={i} className="text-yellow-400" />);
        } else {
            stars.push(<FaRegStar key={i} className="text-yellow-400" />);
        }
    }

    return (
        <div 
            className="rating-container"
            onMouseEnter={() => setShowRating(true)}
            onMouseLeave={() => setShowRating(false)}
        >
            <div className="flex">
                {stars}
            </div>
            {showRating && (
                <div className="rating-tooltip">
                    {rating.toFixed(1)}/5
                </div>
            )}
        </div>
    );
};

function Partners() {
    const mapRef = useRef(null);
    const markerRefs = useRef({});
    
    const handleGoToLocation = (partner) => {
        if (mapRef.current) {
            const map = mapRef.current;
            map.flyTo(partner.position, 15, {
                duration: 2
            });
            
            markerRefs.current[partner.id]?.openPopup();
            
            document.getElementById('map-section')?.scrollIntoView({ 
                behavior: 'smooth',
                block: 'center'
            });
        }
    };

    const getGoogleMapsUrl = (position) => {
        const [lat, lng] = position;
        return `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
    };

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="title-container">
                <h1>Nos Partenaires</h1>
            </div>
            
            <div 
                id="map-section"
                className="mb-12"
                style={{ height: "400px", width: "80%", margin: "0 auto" }}
            >
                <MapContainer
                    center={[46.603354, 1.888334]}
                    zoom={6}
                    style={{ height: "100%", width: "100%" }}
                    ref={mapRef}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    {partners.map(partner => (
                        <Marker 
                            key={partner.id} 
                            position={partner.position}
                            ref={(ref) => markerRefs.current[partner.id] = ref}
                        >
                            <Popup>
                                <div className="text-center">
                                    <h3 className="font-bold mb-2">{partner.name}</h3>
                                    <RatingStars rating={partner.rating} />
                                    <a 
                                        href={getGoogleMapsUrl(partner.position)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="go-to-button popup-button mt-3 text-sm inline-block"
                                    >
                                        M'y rendre
                                    </a>
                                </div>
                            </Popup>
                        </Marker>
                    ))}
                </MapContainer>
            </div>

            <div className="partners-grid">
                {partners.map(partner => (
                    <div key={partner.id} className="partner-card">
                        <img 
                            src={partner.image} 
                            alt={partner.name}
                        />
                        <div className="partner-card-content">
                            <h3>{partner.name}</h3>
                            <div className="partner-rating">
                                <RatingStars rating={partner.rating} />
                            </div>
                            <p className="partner-description">{partner.description}</p>
                            <p className="partner-address">
                                <span className="font-medium">Adresse:</span> {partner.address}
                            </p>
                            <button 
                                onClick={() => handleGoToLocation(partner)}
                                className="go-to-button"
                            >
                                Y aller
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Partners; 