import '../styles/Loader.css';
import React from "react";

function Pre({ load }) {
  React.useEffect(() => {
    if (load) {
      document.documentElement.classList.add('loading');
      document.body.classList.add('loading');
    } else {
      document.documentElement.classList.remove('loading');
      document.body.classList.remove('loading');
    }
  }, [load]);

  return (
    <div id={load ? "preloader" : "preloader-none"}>
      <img 
        src={require("../Assets/qr-code.png")} 
        alt="loading" 
        className="loader-image"
      />
    </div>
  );
}

export default Pre;